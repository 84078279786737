import { graphql } from "gatsby"
import React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import HeroAnimation from "../components/03_organisms/animations/HeroAnimation/HeroAnimation"
import { RenderComponent } from "../components/components"
import * as styles from "../components/components.module.scss"

const IndexPage = ({ data }) => {
  const pageData = data.allNodeProductOverview.nodes[0]
  const products = data.allNodeProduct.nodes
  const components = pageData.relationships.field_components

  return (
    <Layout>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />

      {pageData && (
        <>
          <ImageTextBlock
            height="100"
            className="hero"
            image_position="left"
            background_color="Azure"
            animation={
              <HeroAnimation
                image={
                  <img src="/img/heroes/hero_products.png" alt="Contact" />
                }
                position="left"
                background_color="dusky_rose"
              />
            }
          >
            <Heading size="xxl" spacing="m">
              {pageData.title}
            </Heading>
            <Paragraph>
              <span
                dangerouslySetInnerHTML={{
                  __html: pageData.body.processed,
                }}
              />
            </Paragraph>
          </ImageTextBlock>

          {components?.map((component, index) =>
            component.internal.type === "paragraph__image_text" &&
            !component.field_fullwidth ? (
              <div className={styles.spacing_bottom} key={index}>
                {RenderComponent(component, index, products)}
              </div>
            ) : (
              RenderComponent(component, index, products)
            )
          )}
        </>
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allNodeProductOverview(
      filter: { langcode: { eq: $language }, status: { eq: true } }
    ) {
      nodes {
        body {
          processed
        }
        title
        field_meta_description
        field_meta_title
        relationships {
          field_components {
            ... on Node {
              ...imageTextFragment
              ...productList
              ...videoFragment
            }
          }
          field_image {
            ...imageFragment
          }
        }
        drupal_internal__nid
      }
    }

    allNodeProduct(
      filter: { langcode: { eq: $language }, status: { eq: true } }
      sort: { fields: field_weight, order: ASC }
    ) {
      nodes {
        title
        field_short_description
        path {
          alias
          langcode
        }
        relationships {
          field_main_color {
            name
          }
          field_image {
            ...imageFragment
          }
        }
      }
      totalCount
    }
  }
`
